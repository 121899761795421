// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-rezerwacja-podsumowanie-js": () => import("./../../../src/pages/rezerwacja/podsumowanie.js" /* webpackChunkName: "component---src-pages-rezerwacja-podsumowanie-js" */),
  "component---src-pages-rezerwacja-samochod-js": () => import("./../../../src/pages/rezerwacja/samochod.js" /* webpackChunkName: "component---src-pages-rezerwacja-samochod-js" */),
  "component---src-pages-rezerwacja-ubezpieczenie-js": () => import("./../../../src/pages/rezerwacja/ubezpieczenie.js" /* webpackChunkName: "component---src-pages-rezerwacja-ubezpieczenie-js" */),
  "component---src-pages-rezerwacja-udogodnienia-js": () => import("./../../../src/pages/rezerwacja/udogodnienia.js" /* webpackChunkName: "component---src-pages-rezerwacja-udogodnienia-js" */),
  "component---src-pages-samochody-js": () => import("./../../../src/pages/samochody.js" /* webpackChunkName: "component---src-pages-samochody-js" */),
  "component---src-pages-wynajem-dlugoterminowy-samochodow-js": () => import("./../../../src/pages/wynajem-dlugoterminowy-samochodow.js" /* webpackChunkName: "component---src-pages-wynajem-dlugoterminowy-samochodow-js" */),
  "component---src-pages-wypozyczalnia-samochodow-gdansk-js": () => import("./../../../src/pages/wypozyczalnia-samochodow-gdansk.js" /* webpackChunkName: "component---src-pages-wypozyczalnia-samochodow-gdansk-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-posts-js": () => import("./../../../src/templates/posts.js" /* webpackChunkName: "component---src-templates-posts-js" */)
}


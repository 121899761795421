import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";

// const theme = createMuiTheme({
//   typography: {
//     htmlFontSize: 16,
//     fontFamily: `Lato, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default`
//   },
//   transitions: {
//     // So we have `transition: none;` everywhere
//     create: () => "none"
//   },
//   props: {
//     // Name of the component ⚛️
//     MuiButtonBase: {
//       // The properties to apply
//       disableRipple: true // No more ripple, on the whole application 💣!
//     }
//   },
//   palette: {
//     error: {
//       main: "#e53935"
//     },
//     attention: {
//       main: "#e53935"
//     },
//     primary: {
//       main: "#184377"
//     },
//     secondary: {
//       main: "#F68B1F",
//       contrastText: "#ffffff"
//     },
//     background: {
//       default: "#ffffff"
//     }
//   }
// });

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#556cd6",
    },
    secondary: {
      main: "#19857b",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
});

export default theme;
